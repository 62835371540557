import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAiMessageToast, LazyChatManager, LazyChatMenu, LazyCollapseMenu, LazyDialogHistory, LazyDialogHistoryManagerDialogHistory, LazyGenerateAiMessage, LazyLoader, LazyLogo, LazyModalContent, LazyModalError, LazyModal, LazyPageLoader, LazySendMessage, LazyStopEvent, LazyTextInfo, LazyUploadFile, LazyUploadFilesContent, LazyUploadFilesTeleportContent, LazyUserAvatar, LazyNuxtIcon } from '#components'
const lazyGlobalComponents = [
  ["AiMessageToast", LazyAiMessageToast],
["ChatManager", LazyChatManager],
["ChatMenu", LazyChatMenu],
["CollapseMenu", LazyCollapseMenu],
["DialogHistory", LazyDialogHistory],
["DialogHistoryManagerDialogHistory", LazyDialogHistoryManagerDialogHistory],
["GenerateAiMessage", LazyGenerateAiMessage],
["Loader", LazyLoader],
["Logo", LazyLogo],
["ModalContent", LazyModalContent],
["ModalError", LazyModalError],
["Modal", LazyModal],
["PageLoader", LazyPageLoader],
["SendMessage", LazySendMessage],
["StopEvent", LazyStopEvent],
["TextInfo", LazyTextInfo],
["UploadFile", LazyUploadFile],
["UploadFilesContent", LazyUploadFilesContent],
["UploadFilesTeleportContent", LazyUploadFilesTeleportContent],
["UserAvatar", LazyUserAvatar],
["NuxtIcon", LazyNuxtIcon],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
